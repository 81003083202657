import React from 'react';
import { Box } from '@mui/material';
import { ChatProvider } from '../contexts/ChatContext';
import ChatWindow from './ChatWindow';
import ChatInput from './ChatInput';
import Header from "./Header";

const ChatApp: React.FC = () => {
    return (
        <ChatProvider>
            <Box
                display="flex"
                flexDirection="column"
                height="100vh"
                sx={{ background: '#212121' }}
            >
                <Box width="100%" maxWidth="100vw">
                    <Header />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    flex="1"
                    overflow="auto"
                >
                    <ChatWindow />
                </Box>
                <Box>
                    <ChatInput />
                </Box>
            </Box>
        </ChatProvider>
    );
};

export default ChatApp;
