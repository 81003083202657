import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import Message from './Message';
import { useChat } from "../hooks/useChat";

const ChatWindow: React.FC = () => {
    const { messages, sendingMessageToAgent, loadingConversation, deletingConversation, fetchAnotherPage, hasMore } = useChat();
    const [localMessages, setLocalMessages] = useState(messages);
    const listRef = useRef<HTMLDivElement>(null);
    const prevScrollHeightRef = useRef<number | null>(null);

    useEffect(() => {
        setLocalMessages(messages);
    }, [messages]);

    useEffect(() => {
        if (listRef.current) {
            if (prevScrollHeightRef.current !== null) {
                const newScrollTop = listRef.current.scrollHeight - prevScrollHeightRef.current;
                listRef.current.scrollTop = newScrollTop > 0 ? newScrollTop : 0;
                prevScrollHeightRef.current = null;
            } else {
                listRef.current.scrollTop = listRef.current.scrollHeight;
            }
        }
    }, [localMessages]);

    useEffect(() => {
        const handleScroll = () => {
            if (listRef.current) {
                if (listRef.current.scrollTop === 0 && hasMore) {
                    prevScrollHeightRef.current = listRef.current.scrollHeight;
                    fetchAnotherPage();
                }
            }
        };

        if (listRef.current) {
            listRef.current.addEventListener('scroll', handleScroll);
        }

        const listRefCurrent = listRef.current;

        return () => {
            if (listRefCurrent) {
                listRefCurrent.removeEventListener('scroll', handleScroll);
            }
        };
    }, [hasMore, fetchAnotherPage]);

    useEffect(() => {
        const styleTag = document.createElement("style");
        styleTag.type = "text/css";
        styleTag.id = "scrollbar-styles";
        styleTag.innerHTML = `
            .custom-scrollbar::-webkit-scrollbar {
                width: 12px;
            }
            .custom-scrollbar::-webkit-scrollbar-track {
                background: #2f2f2f;
            }
            .custom-scrollbar::-webkit-scrollbar-thumb {
                background-color: #f0f0f0;
                border-radius: 6px;
                border: 3px solid #1e2b37;
            }
            #chat-container {
                min-height: 600px;
                overflow: hidden auto;
                position: relative;
            }
            @media (max-width: 600px) {
                #chat-container {
                    max-height: calc(100vh - 72px - 56px - 32px);
                }
            }
        `;
        document.head.appendChild(styleTag);

        return () => {
            document.getElementById('scrollbar-styles')?.remove();
        };
    }, []);

    return (
        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            overflow="hidden auto"
            id="chat-container"
            className="custom-scrollbar"
            ref={listRef}
            bgcolor="#212121"
            py={2}
        >
            {localMessages.map((message, index) => {
                const isSending = sendingMessageToAgent && index === localMessages.length - 1;
                return (
                    <Message
                        key={message.id}
                        message={message}
                        isNew={index === localMessages.length - 1}
                        isSending={isSending}
                    />
                );
            })}
            {(loadingConversation || deletingConversation) && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                    position="absolute"
                    top={0}
                    left={0}
                    zIndex={1}
                    bgcolor="rgba(255, 255, 255, 0.05)"
                >
                    <CircularProgress color="success" size={80} />
                </Box>
            )}
        </Box>
    );
};

export default ChatWindow;
