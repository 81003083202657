import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useChat } from '../hooks/useChat';

const Header: React.FC = () => {
    const { restartChat } = useChat();

    const handleRestart = () => {
        restartChat();
    };

    return (
        <Box
            sx={{
                position: 'sticky',
                zIndex: 1000,
                top: 0,
                paddingTop: '5px',
                background: 'linear-gradient(rgb(85, 87, 88), rgb(60, 63, 65))',
                borderBottom: '2px solid rgb(85, 87, 88)',
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '10px 0',
                }}
            >
                <Typography variant="h6" color="white" sx={{ flex: 1, textAlign: 'center' }}>
                    TraderAI
                </Typography>
                <IconButton color="inherit" onClick={handleRestart}>
                    <RefreshIcon sx={{ color: 'white' }} />
                </IconButton>
            </Box>
        </Box>
    );
};

export default Header;
